<template>
    <div class="content-right rounded-3">
    <nav aria-label="breadcrumb">
      
    </nav>
    <div
      class="content-body"
      id="fullHeight"
      style="min-height: calc(100vh - 135px)"
    >
      <div class="row align-items-center">
          <div class="col-5">
          <h2 class="title2">Department</h2>
          </div>
          <div class="col text-end">
              <a class="btn btn-outline-dark me-2" href="/export/fields/department">Export</a>
              <button class="btn btn-outline-dark" @click="showModal">import</button>
          </div>
      </div>
        <div class="t-layout">
          <div class="row">
              <div v-if="$store.state.permittedActions.includes(121)" class="col-md-6">
                  <div class="card">
                <div class="card-header">

                </div>
                <div class="card-body">
                    <flash-message class="myCustomClass"></flash-message>
                    <form  @submit.prevent="onSubmit($event)">
                        <div class="mb-3">
                            <label class="form-label">Department Name</label>
                            <input
                                class="form-control"
                                v-model="$v.form.department.$model"
                                :validator="$v.form.department"
                            />
                            <span
                                class="error "
                                v-if="$v.form.department.$error == true"
                                >Department name is required </span
                            >
                        </div>
                        <div class="text-right">
                            <button type="submit" class="btn btn-outline-primary" >Save</button>
                        </div>
                    </form>
                </div>
              </div>
              </div>
              <div :class="$store.state.permittedActions.includes(121) ? 'col-md-6': 'col-md-12'">

                <div v-if="this.$store.state.loader">
                    <loader object="#f74b3d" color1="#ffffff" color2="#17fd3d" size="5" speed="2" bg="#343a40" objectbg="#999793" opacity="80" disableScrolling="false" name="spinning"></loader>
                </div>
                <div v-else class="card">
                <div class="card-header">
                </div>
                <div class="card-body">

                  <b-table
                    class="table table-custom"
                    :items="departments"
                    :fields="fields"
                    show-empty
                    sticky-header
                  >
                  <template v-slot:cell(department)="data">
                      {{ data.item.department }}
                  </template>
                  <template v-slot:cell(action)="data">
                      <div class="d-inline-block">
                          <div class="d-flex justify-content-md-around">
                            <a v-if="$store.state.permittedActions.includes(122)" class="button button1" title="Edit" @click.prevent="deleteDepartment(data.item.id)"><i class="icon-bin"></i></a>
                          </div>
                      </div>
                  </template>
                  </b-table>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import axios from 'axios';

export default {
   data(){
       return {
           isModalVisible: false,
           form: {
               department: null,
           },
           fields:[
               {
                key: 'department',
                   tdAttr: { 'custom-label'  : "Department:"}
               },
               {
                   key: 'action',
                   tdAttr: { 'custom-label'  : "Action:"}
               }
           ]
       }
   },
   validations: {
       form: {
           department: {
               required
           }
       }
   },
   computed:{
       departments(){
           return this.$store.state.departmentsData;
       }
   },
   created(){
       this.$store.dispatch('loadDepartments');
   },
   methods:{
       showModal() {
        this.isModalVisible = true;
      },
      closeModal() {
        this.isModalVisible = false;
      },
       onSubmit(event){
            this.$v.$touch();
            if (this.$v.$invalid) return;
            axios
                .post("/fields/department/add", this.form)
                .catch(({ response }) => {
                this.flashMessage.error({
                    message: "Something went wrong!",
                    time: 3000,
                });
                })
                .then(({ data }) => {
                if (data.success) {
                    this.form.department = null;
                    this.flashMessage.success({
                    message: data.success,
                    time: 3000,
                    });
                    this.$store.dispatch('loadDepartments');
                } else {
                    this.flashMessage.error({
                    message: data.error,
                    time: 3000,
                    });
                }
                });
       },
       deleteDepartment(id){
           if(confirm('Are you sure?')){
                axios.get("/fields/department/delete/"+id)
                    .catch(({ response }) => {
                    this.flashMessage.error({
                        message: "Something went wrong!",
                        time: 3000,
                    });
                    })
                    .then(({ data }) => {
                    if (data.success) {
                        this.form.department = null;
                        this.flashMessage.success({
                        message: data.success,
                        time: 3000,
                        });
                        this.$store.dispatch('loadDepartments');
                    } else {
                        this.flashMessage.error({
                        message: data.error,
                        time: 3000,
                        });
                    }
                    });
            }
       }
   }
}
</script>
